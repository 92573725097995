<template>
  <div>
   
    <b-card >
    <b-card-body>

      <b-row class="mb-1" align-v="center">
        <b-col md="7">
          <div v-if="searchType">
           
            <b-form-group
              label="Doctor"
              label-for="doctor"
              class="w-50"
              v-if="searchType.value === 1"
            >
              <v-select
                id="doctor"
                v-model="selectedDoctor"
                :options="doctors"
                placeholder="Select doctor"
                label="name"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group label="Search Type" label-for="searchType">
            <v-select
              id="searchType"
              v-model="searchType"
              :options="searchTypes"
              placeholder="Search Type"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row>


            <b-table
              stacked="md"
              :fields="fields"
              :items="consultations"
              details-td-class="m-0 p-0"
              thead-class="tableHeadColor"
              small
              :per-page="0"
              rounded
              
            >
              <template #head()="data">
                <div class="">
                  <span class="font-weight-bolder text-colorBlack">{{
                    data.label
                  }}</span>
                </div>
              </template>

              <template #cell(serial)="row">
                {{ (currentPage - 1) * perPage + row.index + 1 }}
              </template>

              <template #cell(meeting_link_sent)="row">
                <div class="py-50">
                  <b-badge
                    v-if="row.item.meeting_link_sent == true"
                    pill
                    class="font-weight-bolder m-0"
                    variant="success"
                    style="font-size: 0.8rem"
                  >
                    Sent
                  </b-badge>
                  <b-badge
                    v-else
                    pill
                    class="font-weight-bolder m-0"
                    variant="danger"
                    style="font-size: 0.8rem"
                  >
                    Pending
                  </b-badge>
                </div>
              </template>

              <template #cell(is_paid)="row">
                <div class="py-50">
                  <b-badge
                    v-if="!row.item.is_paid"
                    pill
                    class="font-weight-bolder m-0"
                    variant="danger"
                    style="font-size: 0.8rem"
                  >
                    Pending
                  </b-badge>
                  <b-badge
                    v-else
                    pill
                    class="font-weight-bolder m-0"
                    variant="danger"
                    style="font-size: 0.8rem"
                  >
                    Paid
                  </b-badge>
                </div>
              </template>
              <template #cell(manage)="row">
                <div v-if="row.item.is_paid == true && row.item.meeting_link_sent == false">
                  <b-button
                    pill
                    size="sm"
                    variant="colorGreen"
                    @click="viewMeetingLinkModal(row.item)"
                  >
                    Send Link
                  </b-button>
                  
                </div>
              </template>
            </b-table>
            <b-pagination
              size="md"
              :total-rows="totalItems"
              v-model="currentPage"
              :per-page="perPage"
            ></b-pagination>

            <h6>
              <b>Total Rows: {{ totalItems }}</b>
            </h6>
          </b-card-body>
      </b-card>
    

      <meeting-link-modal
      :consultation="consultingRecord"
      @modalClosed="onModalClosed"
      :key="`view-${MeetingLinkModal}`"
    />
  </div>
  
</template>




<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import MeetingLinkModal from "@/components/admin/csr/MeetingLinkModal.vue";



export default {
  components: {
    MeetingLinkModal
  },
  data() {
    return {
      fields: [
        { key: "serial", label: "S. No" },
        { key: "patient_name", label: "Patient" },
        { key: "doctor_name", label: "Doctor" },
        { key: "created_at", label: "Created AT" },
        { key: "created_by", label: "Created BY" },
        { key: "updated_at", label: "Updated AT" },
        { key: "updated_by", label: "Updated BY" },
        { key: "consultation_time", label: "Consultation Time" },
        { key: "meeting_link_sent", label: "Meeting Link" },
        { key: "is_paid", label: "Payment" },
        { key: "manage", label: "Action" },
      ],

      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      consultations: [],
      consultation: null,
      consultingRecord: null,

      MeetingLinkModal: 0,
     
      searchTypes: [
        { value: 1, name: "Doctor" },
      ],
      searchType: null,
      name: "",
      doctors: [],
      selectedDoctor: null,
      
    };
  },
  mixins: [util],
  created() {
    this.searchType = this.searchTypes[0];
  },
  async mounted() {
    await this.fetchPaginatedData();
    await this.fetchDoctors();
  },
  methods: {
    ...mapActions({
      getConsultations: "appData/getConsultations",
      getDoctorsPublic: "appData/getDoctorsPublic"
    }),
    async search() {
      if (this.searchType) {
        switch (this.searchType.value) {
          case 1:
            break;
        }
      } else {
        this.selectedDoctor = null;
      }
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },

    async fetchDoctors() {
      try {
        const res = await this.getDoctorsPublic({hospital: null, department: null});
        this.doctors = res.data;
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    },
  
    async fetchPaginatedData(){
      try {
        const res = await this.getConsultations({
          pageNumber: this.currentPage,
          doctor: this.selectedDoctor ? this.selectedDoctor.id : null,
        });
          this.consultations = res.data.results;
          this.totalItems = res.data.count;
          this.perPage = res.data.per_page;

      } catch (error) {
        this.displayError(error);
      }
    },

    viewMeetingLinkModal(consultation) {
      this.consultingRecord = consultation;
      this.MeetingLinkModal += 1;
      this.$nextTick(() => {
        this.$bvModal.show("meeting-link-modal");
      });
    },

    async onModalClosed(){
      await this.fetchPaginatedData();
    }
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
    
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
};
</script>

<style>
.bg1 {
  color: red;
  font-weight: normal;
}
.is-invalid {
  border-color: red !important;
}

.invalid-feedback {
  color: red;
  font-size: 80%;
}
</style>
